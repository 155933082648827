
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Nama Jabatan">
                  <CInput
                    label="Nama Jabatan"
                    placeholder="Nama Jabatan"
                    v-model="form.nama_jabatan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3" v-if="item">
                <CInput
                  label="Kode Jabatan"
                  placeholder
                  readonly
                  v-model="form.id_jabatan_fungsional"
                ></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <validation-provider
                  rules="required|numeric"
                  v-slot="{ errors }"
                  name="Tunjangan Jabatan"
                >
                  <CInput
                    label="Tunjangan Jabatan"
                    placeholder="Tunjangan Jabatan"
                    v-model="form.tunjangan_jabatan"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider
                  rules="required|numeric"
                  v-slot="{ errors }"
                  name="Angka Kredit"
                >
                  <CInput
                    label="Angka Kredit"
                    placeholder="Angka Kredit"
                    v-model="form.angka_kredit"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider rules="required|numeric" v-slot="{ errors }" name="Batas Usia">
                  <CInput
                    label="Batas Usia Pensiun"
                    placeholder="Batas Usia Pensiun"
                    v-model="form.bup"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  ></CInput>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Golongan Awal">
                  <div role="group" class="form-group">
                    <label class>Golongan Awal</label>
                    <v-select
                      v-model="form.golongan_awal"
                      :options="optionsGolonganRuang"
                      label="pangkat_golongan_ruang"
                      :reduce="golongan => golongan.id"
                      :filterable="true"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider rules="required" v-slot="{ errors }" name="Golongan Akhir">
                  <div role="group" class="form-group">
                    <label class>Golongan Akhir</label>
                    <v-select
                      v-model="form.golongan_akhir"
                      :options="optionsGolonganRuang"
                      label="pangkat_golongan_ruang"
                      :reduce="golongan => golongan.id"
                      :filterable="true"
                      :class="[
                            { 'is-valid': !errors[0] },
                            { 'is-invalid': errors[0] },
                          ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CTextarea v-model="form.ikhtisar_jabatan" label="Iktisar Jabatan" rows="9" />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <div role="group" class="form-group">
                  <label class>Keterangan</label>
                  <CInputRadioGroup
                    :checked.sync="form.keterangan"
                    :options="[{ value: '0', label: 'JAPEL'},{ value: '1', label: 'JF'}]"
                    :inline="true"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <div role="group" class="form-group">
                  <label class>Kriteria</label>
                  <CInputRadioGroup
                    :checked.sync="form.kriteria"
                    :options="[{ value: '0', label: 'Keahlian'},{ value: '1', label: 'Keterampilan'}]"
                    :inline="true"
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else>Simpan</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
import VSelect from "vue-select";
import { mapGetters } from "vuex";
export default {
  name: "FormInformasiUmum",
  props: ["item"],
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  data() {
    return {
      errorMessage: null,
      form: {
        id_jabatan_fungsional: null,
        nama_jabatan: null,
        tunjangan_jabatan: null,
        angka_kredit: null,
        bup: null,
        ikhtisar_jabatan: null,
        golongan_awal: null,
        golongan_akhir: null,
        kriteria: null,
        keterangan: null,
      },
      loading: false,
    };
  },
  watch: {
    item: function (item) {
      if (item) {
        this.form.id_jabatan_fungsional = item.id_jabatan_fungsional;
        this.form.nama_jabatan = item.nama_jabatan;
        this.form.tunjangan_jabatan = item.tunjangan_jabatan;
        this.form.angka_kredit = item.angka_kredit;
        this.form.bup = item.bup;
        this.form.ikhtisar_jabatan = item.ikhtisar_jabatan;
        this.form.golongan_awal = parseInt(item.golongan_awal);
        this.form.golongan_akhir = parseInt(item.golongan_akhir);
        this.form.kriteria =
          item.kriteria == 0 || item.kriteria == 1
            ? item.kriteria.toString()
            : item.kriteria;
        this.form.keterangan =
          item.keterangan == 0 || item.keterangan == 1
            ? item.keterangan.toString()
            : item.keterangan;
      }
    },
  },
  computed: {
    ...mapGetters("master_golongan_ruang", {
      optionsGolonganRuang: "options",
    }),
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("jabatan_fungsional_informasi/update", {
              data: this.form,
              id_jabatan_fungsional: vm.item.id_jabatan_fungsional,
            })
          : await this.$store.dispatch(
              "jabatan_fungsional_informasi/store",
              this.form
            );
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.id_jabatan_fungsional = null;
              vm.form.nama_jabatan = null;
              vm.form.tunjangan_jabatan = null;
              vm.form.angka_kredit = null;
              vm.form.bup = null;
              vm.form.ikhtisar_jabatan = null;
              vm.form.golongan_awal = null;
              vm.form.golongan_akhir = null;
              vm.form.kriteria = null;
              vm.form.keterangan = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
